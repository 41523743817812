import React from 'react';
import { Clock, Loader2, CheckCircle2, Download } from 'lucide-react';
import { Alert, Group, Text, Button } from '@mantine/core';

export enum RevForcedAlignmentStatus {
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    NOT_STARTED = 'not_started',
}

interface ForcedAlignmentTrackerProps {
    status: RevForcedAlignmentStatus;
    onStartAlignment: () => void;
    onDownloadSRT: () => void;
    isLoading?: boolean;
}


export const ForcedAlignmentTracker = ({
                                           status,
                                           onStartAlignment,
                                           onDownloadSRT,
                                           isLoading = false
                                       }: ForcedAlignmentTrackerProps) => {
    const getStatusConfig = () => {
        switch (status) {
            case RevForcedAlignmentStatus.COMPLETED:
                return {
                    icon: <CheckCircle2 size={18} />,
                    title: 'Forced Alignment Complete',
                    description: 'Processing finished successfully',
                    color: 'teal',
                    button: (
                        <Button
                            variant="light"
                            color="teal"
                            radius="xl"
                            size="xs"
                            leftSection={<Download size={14} />}
                            onClick={onDownloadSRT}
                        >
                            Download SRT
                        </Button>
                    )
                };
            case RevForcedAlignmentStatus.IN_PROGRESS:
                return {
                    icon: <Loader2 size={18} className="animate-spin" />,
                    title: 'Processing',
                    description: 'Currently aligning audio with transcript',
                    color: 'blue',
                    button: (
                        <Button
                            variant="light"
                            color="blue"
                            radius="xl"
                            size="xs"
                            disabled
                        >
                            Syncing Audio and Text
                        </Button>
                    )
                };
            default:
                return {
                    icon: <Clock size={18} />,
                    title: 'Waiting to Start',
                    description: 'Forced alignment process will begin shortly',
                    color: 'gray',
                    button: (
                        <Button
                            variant="light"
                            color="gray"
                            radius="xl"
                            size="xs"
                            onClick={onStartAlignment}
                            disabled={isLoading}
                            leftSection={isLoading ? <Loader2 size={14} className="animate-spin" /> : undefined}
                        >
                            {isLoading ? 'Starting...' : 'Start Forced Alignment Job'}
                        </Button>
                    )
                };
        }
    };

    const config = getStatusConfig();

    return (
        <Alert
            color={config.color}
            radius="md"
            variant="light"
            styles={{
                wrapper: {
                    alignItems: 'center'
                }
            }}
        >
            <Group justify="space-between" style={{ width: '100%' }}>
                <Group gap="sm" align="center">
                    {config.icon}
                    <div>
                        <Text fw={700} size="sm">
                            {config.title}
                        </Text>
                        <Text c="dimmed" size="xs">
                            {config.description}
                        </Text>
                    </div>
                </Group>
                {config.button}
            </Group>
        </Alert>
    );
};
