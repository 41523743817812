import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    useDownloadSRT,
    useGetAsrTranscript,
    useGetFinalTranscript, useGetForcedAlignmentStatus,
    useGetProofreadTranscript, useStartForcedAlignment
} from "../../../hooks/useLocalizationQueries";
import { useUser } from "../../../context/UserContext";
import { useAuth } from "../../../context/AuthContext";
import { useLibraryNavigation } from "../../../context/LibraryNavigationContext";
import { useParams } from "react-router-dom";
import {Editable, Slate, useSlate, useFocused, ReactEditor} from "slate-react";
import {Editor, Range, Transforms} from 'slate';
import { TranscriptMode } from "../../../models/Transcript";
import { TaskState, TranscriptionTaskStates } from "../../../studio/transcription-studio";
import {
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SkeletonText,
    Text,
    useToast
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { FaBold, FaItalic, FaUnderline } from "react-icons/fa6";
import { rem } from "@mantine/core";
import { handleSplitParagraph } from "../../editors/slate-helpers";
import { ToolbarButton, ToolbarMenu, ToolbarPortal } from "../../editors/toolbar-helper";
import {
    useTranscriptionState,
    renderLeaf,
    DefaultElement,
    getHighlightedTimestamps,
    toggleFormatMark,
    isMarkActive,
    generateDocument,
    useTranscriptDataEffect, generateSrtFile
} from './localization-transcription-utils';
import {ForcedAlignmentTracker} from "../../../studio/forced-alignment-tracker";

// Common HoveringToolbar component used across all transcription components
const HoveringToolbar = () => {
    const ref = React.useRef<HTMLDivElement | null>();
    const editor = useSlate();
    const inFocus = useFocused();

    useEffect(() => {
        try {
            const el = ref.current;
            const { selection } = editor;
            if (!el) return;

            if (
                !selection ||
                !inFocus ||
                Range.isCollapsed(selection) ||
                Editor.string(editor, selection) === ''
            ) {
                el.removeAttribute('style');
                return;
            }

            const domSelection: any = window.getSelection();
            const domRange = domSelection.getRangeAt(0);
            const rect = domRange.getBoundingClientRect();
            el.style.opacity = '1';
            el.style.top = `${rect.bottom + window.scrollY}px`;
            el.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (el.offsetWidth / 2)}px`;
        } catch (e) {
            console.error(e);
        }
    });

    const FormatButton = ({ format, icon }: { format: string, icon: React.ReactNode }) => {
        const editor = useSlate();
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => toggleFormatMark(editor, format)}
            >
                <Icon>{icon}</Icon>
            </ToolbarButton>
        );
    };

    return (
        <ToolbarPortal ref={ref}>
            <ToolbarMenu
                ref={ref}
                onMouseDown={(e: any) => { e.preventDefault(); }}
            >
                <FormatButton format="bold" icon={<FaBold />} />
                <FormatButton format="italic" icon={<FaItalic />} />
                <FormatButton format="underlined" icon={<FaUnderline />} />
            </ToolbarMenu>
        </ToolbarPortal>
    );
};

// Base component for all transcription views
const BaseTranscriptionComponent = ({
                                        mode,
                                        states,
                                        useTranscriptQuery,
                                        shouldFetch
                                    }: {
    mode: TranscriptMode,
    states: TranscriptionTaskStates,
    useTranscriptQuery: any,
    shouldFetch: boolean
}) => {
    const user = useUser();
    const auth = useAuth();
    const libraryNavigation: any = useLibraryNavigation();
    const { sessionId } = useParams();
    const toast = useToast();

    const {
        editor,
        transcriptLoading,
        setTranscriptLoading,
        task,
        setTask,
        value,
        setValue,
        transcriptRef
    } = useTranscriptionState(libraryNavigation.studioSession);

    const { data: transcript, isLoading, error } = useTranscriptQuery(
        task,
        states,
        mode,
        shouldFetch
    );

    useTranscriptDataEffect(
        isLoading,
        error,
        transcript,
        setValue,
        setTask,
        setTranscriptLoading,
        toast
    );

    const renderElement = useCallback(({...props}) => {
        const list = props.element.speakersList;
        switch (props.element.type) {
            case 'timedText':
                return (
                    <Flex direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} w={'full'} {...props.attributes} sx={{
                        textColor: '#171923',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                    }}>
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Text contentEditable={false} color="teal.600" fontSize="12px" fontWeight="600" lineHeight="16px">
                                    {props.element.speaker}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                {list.map((speaker: any) => (
                                    <MenuItem
                                        key={speaker.name}
                                        contentEditable={false}
                                        onClick={() => handleSetSpeaker(props, speaker.name)}
                                    >
                                        {speaker.name}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        <p>{props.children}</p>
                        <br/>
                    </Flex>
                );
            default:
                return <DefaultElement {...props} />;
        }
    }, []);

    const handleSetSpeaker = (props: any, name: string) => {
        const pathToCurrentNode = ReactEditor.findPath(editor, props.element);
        const oldSpeakerName = props.element.speaker;
        const isUpdateAllSpeakerInstances = window.confirm(
            `Would you like to replace all occurrences of ${oldSpeakerName} with ${name}?`
        );

        if (isUpdateAllSpeakerInstances) {
            const rangeForTheWholeEditor = Editor.range(editor, []);
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<any>,
                {
                    at: rangeForTheWholeEditor,
                    match: (node: any) => node.type === 'timedText' &&
                        node.speaker.toLowerCase() === oldSpeakerName.toLowerCase(),
                }
            );
        } else {
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<any>,
                { at: pathToCurrentNode }
            );
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === '&') {
            event.preventDefault();
            editor.insertText('and');
        }

        if (event.key === 'Enter') {
            event.preventDefault();
            handleSplitParagraph(editor);
        }
    };

    return (
        <Flex w={'full'} h={'100vh'} direction={'column'} gap={'36px'}>
            <Flex ref={transcriptRef} w={'full'} h={'100%'} style={{ overflow: 'scroll' }}>
                {transcriptLoading ? (
                    <SkeletonText w={'full'} h={'full'} mt='4' noOfLines={60} spacing='4' skeletonHeight='2' />
                ) : (
                    <Flex direction={'column'} gap={'10px'} align={'center'} justify={'center'} w={'100%'} h={'100%'}>
                        <Slate editor={editor} onChange={setValue} value={value}>
                            <HoveringToolbar />
                            <Editable
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                onKeyDown={handleKeyDown}
                                style={{ height: '100vh', overflowY: 'scroll' }}
                            />
                        </Slate>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

// Specific implementations for different transcription modes
export const StudioAITranscriptionComponent = ({ mode, states }: {
    mode: TranscriptMode,
    states: TranscriptionTaskStates
}) => {
    const shouldFetch = useMemo(() =>
            mode === TranscriptMode.ASR &&
            (states["AI"] === TaskState.IN_PROGRESS || states["AI"] === TaskState.COMPLETED),
        [mode, states]);

    return (
        <BaseTranscriptionComponent
            mode={mode}
            states={states}
            useTranscriptQuery={useGetAsrTranscript}
            shouldFetch={shouldFetch}
        />
    );
};

export const StudioProofreadTranscriptionComponent = ({ mode, states }: {
    mode: TranscriptMode,
    states: TranscriptionTaskStates
}) => {
    const shouldFetch = useMemo(() =>
            mode === TranscriptMode.PROOFREAD &&
            (states["TRANSCRIPTIONIST"] === TaskState.IN_PROGRESS ||
                states["TRANSCRIPTIONIST"] === TaskState.COMPLETED),
        [mode, states]);

    return (
        <BaseTranscriptionComponent
            mode={mode}
            states={states}
            useTranscriptQuery={useGetProofreadTranscript}
            shouldFetch={shouldFetch}
        />
    );
};

export const StudioCompletedTranscriptionComponent = ({ mode, states, session }: {
    mode: TranscriptMode,
    states: TranscriptionTaskStates,
    session: any
}) => {
    const toast = useToast();
    const shouldFetch = useMemo(() =>
            mode === TranscriptMode.COMPLETED &&
            states["FINAL"] === TaskState.COMPLETED,
        [mode, states]);

    const { mutate: startAlignment } = useStartForcedAlignment(session);
    const { data: alignmentStatus } = useGetForcedAlignmentStatus(session);
    const [downloadSrt, setDownloadSrt] = useState(false);
    const { data: captions, isLoading: captionsLoading } = useDownloadSRT(session, downloadSrt);
    const [isStartingAlignment, setIsStartingAlignment] = useState(false);

    // Update local status if polling returns new status
    useEffect(() => {
        if (alignmentStatus && session) {
            session.forced_alignment.status = alignmentStatus.status;
        }
    }, [alignmentStatus, session]);

    const captionsData = useMemo(() => captions || '', [captions]);

    useEffect(() => {
        if (downloadSrt && captionsData) {
             generateSrtFile(captionsData, session);
             toast({
                 title: 'Download Complete',
                 description: 'SRT file has been downloaded.',
                 status: 'success',
                 duration: 2000,
             });
             setDownloadSrt(false);
        }
    }, [captionsData, downloadSrt]);

    const handleStartAlignment = async () => {
        setIsStartingAlignment(true);
        startAlignment(undefined, {
            onSuccess: () => {
                toast({
                    title: 'Alignment Started',
                    description: 'Forced alignment process has been initiated.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setIsStartingAlignment(false);
            },
            onError: (error) => {
                toast({
                    title: 'Alignment Failed',
                    description: 'Failed to start forced alignment process. Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error('Failed to start alignment:', error);
                setIsStartingAlignment(false);
            }
        });
    };

    const handleDownloadSRT = async () => {
        try {
            // API call to download the SRT file
            setDownloadSrt(true);
            toast({
                title: 'Downloading Started',
                description: 'Your SRT file is being downloaded.',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Download Failed',
                description: 'Failed to download SRT file. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Failed to download SRT:', error);
        }
    };

    return (
        <Flex w={'full'} h={'100%'} direction={'column'} gap={'sm'}>
            {session &&
                <ForcedAlignmentTracker
                    status={session.forced_alignment.status}
                    isLoading={isStartingAlignment}
                    onStartAlignment={handleStartAlignment}
                    onDownloadSRT={handleDownloadSRT} />
            }
            <BaseTranscriptionComponent
                mode={mode}
                states={states}
                useTranscriptQuery={useGetFinalTranscript}
                shouldFetch={shouldFetch}
            />
        </Flex>
    );
};
