import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import React, {useEffect, useState} from "react";
import {PodcastTranscriptionTask, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {IconCircleCheck, IconCircleDashedCheck, IconProgressCheck} from "@tabler/icons-react";
import {Flex, Paper, Tabs, Text} from "@mantine/core";
import TranscriptReader from "../../pages/transcript-reader";
import {TranscriptMode} from "../../models/Transcript";
import {TaskState, TranscriptionTaskStates} from "../../studio/transcription-studio";
import {
    StudioAITranscriptionComponent, StudioCompletedTranscriptionComponent, StudioProofreadTranscriptionComponent
} from "../../components/transcript-editors/transcript-components/transcription-static-reader";
import TranscriptProgress
    from "../../components/transcript-editors/transcript-components/transcript-progress-component";

export default function TranscriptionTaskSidebar() {
    const libraryNavigation: any = useLibraryNavigation()
    const [session, setSession] = useState<any>(libraryNavigation.studioSession)
    const [activeTab, setActiveTab] = useState<string | null>('first');

    const [taskStates, setTaskStates] = useState<TranscriptionTaskStates>({
        AI: TaskState.NOT_STARTED,
        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
        EDITOR: TaskState.NOT_STARTED,
        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
        FINAL: TaskState.NOT_STARTED
    })

    useEffect(() => {
        if (session) {
            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    setTaskStates({
                        AI: TaskState.IN_PROGRESS,
                        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    setActiveTab('first')
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    setActiveTab('second')
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    setActiveTab('second')
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    setActiveTab('third')

                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    setActiveTab('third')
                    break
                }
                case TranscriptionState.COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.COMPLETED,
                        FINAL: TaskState.COMPLETED
                    })
                    setActiveTab('fifth')
                    break
                }
            }
        }
    }, [session]);

    const StateIcon = ({state}: {state: TaskState}) => {
        return (
            <>
                {state === TaskState.NOT_STARTED && <IconCircleDashedCheck stroke={2} style={{color: 'gray'}}/>}
                {state === TaskState.IN_PROGRESS && <IconProgressCheck stroke={2} style={{color: 'orange'}}/>}
                {state === TaskState.COMPLETED && <IconCircleCheck stroke={2} style={{color: 'teal'}}/>}
            </>
        )
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case 'first':
                return taskStates.AI === TaskState.COMPLETED ?
                    <StudioAITranscriptionComponent mode={TranscriptMode.ASR} states={taskStates}/> :
                    <TranscriptProgress taskState={taskStates.AI} />;
            case 'second':
                return taskStates.TRANSCRIPTIONIST === TaskState.COMPLETED ?
                    <StudioProofreadTranscriptionComponent mode={TranscriptMode.PROOFREAD} states={taskStates}/> :
                    <TranscriptProgress taskState={taskStates.TRANSCRIPTIONIST} />;
            case 'third':
                return taskStates.EDITOR === TaskState.COMPLETED ?
                    <TranscriptReader mode={TranscriptMode.REVIEWING} states={taskStates}/> :
                    <TranscriptProgress taskState={taskStates.EDITOR} />;
            case 'fifth':
                return taskStates.FINAL === TaskState.COMPLETED ?
                    <StudioCompletedTranscriptionComponent mode={TranscriptMode.COMPLETED} states={taskStates} session={session}/> :
                    <TranscriptProgress taskState={taskStates.FINAL} />;
            default:
                return null;
        }
    };

    return (
        <Flex w={'100%'} direction="column" gap={'32px'}>
            <Paper p="md" h={'100%'} radius={0} style={{ borderBottom: '1px solid #eee' }}>
                <Tabs value={activeTab} onChange={setActiveTab} w={'100%'}>
                    <Tabs.List grow justify="center" bg={'white'}>
                        <Tabs.Tab value="first" rightSection={<StateIcon state={taskStates.AI}/>}>AI</Tabs.Tab>
                        <Tabs.Tab value="second" rightSection={<StateIcon state={taskStates.TRANSCRIPTIONIST}/>}>Transcriptionist</Tabs.Tab>
                        <Tabs.Tab value="third" rightSection={<StateIcon state={taskStates.EDITOR}/>}>Editor</Tabs.Tab>
                        <Tabs.Tab value="fifth" rightSection={<StateIcon state={taskStates.FINAL}/>}>Final</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                        {taskStates.AI === TaskState.COMPLETED ?
                            <StudioAITranscriptionComponent mode={TranscriptMode.ASR} states={taskStates}/> :
                            <TranscriptProgress taskState={taskStates.AI} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="second">
                        {taskStates.TRANSCRIPTIONIST === TaskState.COMPLETED ?
                            <StudioProofreadTranscriptionComponent mode={TranscriptMode.PROOFREAD} states={taskStates}/> :
                            <TranscriptProgress taskState={taskStates.TRANSCRIPTIONIST} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="third">
                        {taskStates.EDITOR === TaskState.COMPLETED ?
                            <TranscriptReader mode={TranscriptMode.REVIEWING} states={taskStates}/> :
                            <TranscriptProgress taskState={taskStates.EDITOR} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="fifth">
                        {taskStates.FINAL === TaskState.COMPLETED ?
                            <StudioCompletedTranscriptionComponent mode={TranscriptMode.COMPLETED} states={taskStates} session={session}/> :
                            <TranscriptProgress taskState={taskStates.FINAL} />
                        }
                    </Tabs.Panel>
                </Tabs>
            </Paper>
        </Flex>
    )
}
